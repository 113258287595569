@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 38, 38, 38;
  --background-end-rgb: 0, 0, 0;
}

@media screen {
  .print {
    display: none !important;
    width: 0px;
    height: 0px;
  }
  .screen {}

  body {
    color: rgb(var(--foreground-rgb));
    background: linear-gradient(
        to bottom,
        transparent,
        rgb(var(--background-end-rgb))
      )
      rgb(var(--background-start-rgb)) fixed no-repeat;
    
    height: 100%;
  }

  html, body {
    height: 100%;
  }
  .fullpage {
    height: 100%;
  }
}

@media print {
  .print {}
  .screen {
    display: none !important;
    width: 0px;
    height: 0px;
  }
 
  body {
    margin: 1.6cm;
  }
  @page {
    margin: 0;
  }
}

blockquote {
  padding-left: 16px;
  border-left: 3px solid rgb(239, 240, 244);
  border-radius: 2px;
  margin-top: 16px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 16px;
}

blockquote:before {
  color: rgb(239, 240, 244);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

h1 {
  font-weight: 700;
  line-height: 1;
  font-size: 32px;
  margin-top: 16px;
}

h2 {
  font-weight: 700;
  line-height: 1;
  font-size: 24px;
  margin-top: 12px;
}

h3 {
  font-weight: 700;
  line-height: 1;
  font-size: 20px;
  margin-top: 10px;
}

h4 {
  font-weight: 700;
  line-height: 1;
  font-size: 18px;
  margin-top: 8px;
}

h5 {
  font-weight: 700;
  line-height: 1;
  font-size: 16px;
  margin-top: 8px;
}

h6 {
  font-weight: 700;
  line-height: 1;
  font-size: 16px;
  margin-top: 8px;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}